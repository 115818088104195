import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Br, Prev, Text } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <Prev path='/dz/v12' mdxType="Prev" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第十三卷-嘱累人天品"
    }}>{`第十三卷 嘱累人天品`}</h3>
    <Text mdxType="Text">
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="jǔ" zi="举" mdxType="Zu" />
  <Zu yin="jīn" zi="金" mdxType="Zu" />
  <Zu yin="sè" zi="色" mdxType="Zu" />
  <Zu yin="bì" zi="臂" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="dǐnɡ" zi="顶" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="bēi" zi="悲" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="zhì" zi="智" mdxType="Zu" />
  <Zu yin="huì" zi="慧" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="biàn" zi="辩" mdxType="Zu" />
  <Zu yin="cái" zi="才" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhènɡ" zi="正" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="xuān" zi="宣" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jì" zi="记" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="rì" zi="日" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="bá" zi="八" mdxType="Zu" />
  <Zu yin="bù" zi="部" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zài" zi="再" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="zhái" zi="宅" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fù" zi="付" mdxType="Zu" />
  <Zu yin="zhǔ" zi="嘱" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="rì" zi="日" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="bí" zi="鼻" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dònɡ" zi="动" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="nán" zi="南" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhì" zi="志" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="性" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="dìnɡ" zi="定" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xí" zi="习" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zònɡ" zi="纵" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xū" zi="须" mdxType="Zu" />
  <Zu yin="yú" zi="臾" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="zēnɡ" zi="增" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="长" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="fēn" zi="分" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="huà" zi="化" mdxType="Zu" />
  <Zu yin="dù" zi="度" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suí" zi="随" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="性" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="dù" zi="度" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="yīn" zi="殷" mdxType="Zu" />
  <Zu yin="qín" zi="勤" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fù" zi="付" mdxType="Zu" />
  <Zu yin="zhǔ" zi="嘱" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="máo" zi="毛" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="chén" zi="尘" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="dī" zi="渧" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yōnɡ" zi="拥" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiàn" zi="渐" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wù" zi="勿" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="shī" zi="失" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suí" zi="随" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="yìnɡ" zi="应" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="shǒu" zi="首" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jù" zi="句" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jì" zi="偈" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="chénɡ" zi="乘" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="diǎn" zi="典" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="jiù" zi="救" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="biān" zi="边" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="suì" zi="碎" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiǎn" zi="遣" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="jì" zi="偈" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="yīn" zi="殷" mdxType="Zu" />
  <Zu yin="qín" zi="勤" mdxType="Zu" />
  <Zu yin="fù" zi="付" mdxType="Zu" />
  <Zu yin="zhǔ" zi="嘱" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="dù" zi="度" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wù" zi="勿" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hú" zi="胡" mdxType="Zu" />
  <Zu yin="ɡuì" zi="跪" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="lǜ" zi="虑" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dù" zi="度" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="sù" zi="速" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="xínɡ" zi="行" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="rán" zi="然" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yǔ" zi="语" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="xū" zi="虚" mdxType="Zu" />
  <Zu yin="kōnɡ" zi="空" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="shì" zi="势" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="diǎn" zi="典" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="zì" zi="字" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="jǐ" zi="几" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="？" zi="？" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="luè" zi="略" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="xū" zi="虚" mdxType="Zu" />
  <Zu yin="kōnɡ" zi="空" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="dì" zi="谛" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="dì" zi="谛" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="！" zi="！" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="fēn" zi="分" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="dú" zi="读" mdxType="Zu" />
  <Zu yin="sònɡ" zi="诵" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="香" mdxType="Zu" />
  <Zu yin="huā" zi="华" mdxType="Zu" />
  <Zu yin="yǐn" zi="饮" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="fu" zi="服" mdxType="Zu" />
  <Zu yin="zhēn" zi="珍" mdxType="Zu" />
  <Zu yin="bǎo" zi="宝" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="bā" zi="八" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="ɡuǒ" zi="果" mdxType="Zu" />
  <Zu yin="rì" zi="日" mdxType="Zu" />
  <Zu yin="zēnɡ" zi="增" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="jí" zi="集" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="yīn" zi="因" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="fēnɡ" zi="丰" mdxType="Zu" />
  <Zu yin="zú" zi="足" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="jí" zi="疾" mdxType="Zu" />
  <Zu yin="yì" zi="疫" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="lí" zi="离" mdxType="Zu" />
  <Zu yin="shuǐ" zi="水" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="zāi" zi="灾" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bā" zi="八" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="dào" zi="盗" mdxType="Zu" />
  <Zu yin="zéi" zi="贼" mdxType="Zu" />
  <Zu yin="è" zi="厄" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiǔ" zi="九" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="qīn" zi="钦" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="zhù" zi="助" mdxType="Zu" />
  <Zu yin="chí" zi="持" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="chén" zi="臣" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="duān" zi="端" mdxType="Zu" />
  <Zu yin="zhènɡ" zi="正" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="相" mdxType="Zu" />
  <Zu yin="hǎo" zi="好" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="dì" zi="帝" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sù" zi="宿" mdxType="Zu" />
  <Zu yin="zhì" zi="智" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="jiē" zi="皆" mdxType="Zu" />
  <Zu yin="cónɡ" zi="从" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="bā" zi="八" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="huān" zi="欢" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="jiǔ" zi="九" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="hènɡ" zi="横" mdxType="Zu" />
  <Zu yin="xiāo" zi="消" mdxType="Zu" />
  <Zu yin="miè" zi="灭" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="chú" zi="除" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="chù" zi="处" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="mènɡ" zi="梦" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="xiān" zi="先" mdxType="Zu" />
  <Zu yin="wánɡ" zi="亡" mdxType="Zu" />
  <Zu yin="lí" zi="离" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sù" zi="宿" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="cōnɡ" zi="聪" mdxType="Zu" />
  <Zu yin="mínɡ" zi="明" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="ráo" zi="饶" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="mǐn" zi="愍" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="bā" zi="八" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="xū" zi="虚" mdxType="Zu" />
  <Zu yin="kōnɡ" zi="空" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="xínɡ" zi="行" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sù" zi="速" mdxType="Zu" />
  <Zu yin="chāo" zi="超" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="xiāo" zi="消" mdxType="Zu" />
  <Zu yin="miè" zi="灭" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zēnɡ" zi="增" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="长" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sù" zi="宿" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="jiē" zi="皆" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="bá" zi="八" mdxType="Zu" />
  <Zu yin="bù" zi="部" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="释" mdxType="Zu" />
  <Zu yin="jiā" zi="迦" mdxType="Zu" />
  <Zu yin="móu" zi="牟" mdxType="Zu" />
  <Zu yin="ní" zi="尼" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="yánɡ" zi="扬" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="cénɡ" zi="曾" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǔ" zi="雨" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="香" mdxType="Zu" />
  <Zu yin="huā" zi="华" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="zhū" zi="珠" mdxType="Zu" />
  <Zu yin="yīnɡ" zi="璎" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="shì" zi="释" mdxType="Zu" />
  <Zu yin="jiā" zi="迦" mdxType="Zu" />
  <Zu yin="móu" zi="牟" mdxType="Zu" />
  <Zu yin="ní" zi="尼" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yǐ" zi="已" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
    </Text>
    <Prev path='/dz/v12' mdxType="Prev" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      